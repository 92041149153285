import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <article>
        <h1 className="gradient-background">Enzo Zuccolotto</h1>
        <p className="huge-text">Hello there <span aria-label="wave hand" role="img">👋👋</span></p>
        <p>I'm software engineer working on fintech <span aria-label="guy in a computer" role="img">👨‍💻</span><span aria-label="pride flag" role="img">🏳️‍🌈</span> </p>
        <p>From <span arial-label="brazilian flag">🇧🇷</span> Brasil and currently living in <span aria-label='german flag'>🇩🇪</span> Berlin.</p>
        <p>Currently I work at <a href="https://nubank.com.br/">Nubank</a> improving the life of millions by offering them better services.</p>
        <br/>
        <p>You can find more from me here:</p>
          <ul>
            <li><p><a href="https://github.com/enzoz/" target="new">GitHub</a></p></li>
            <li><p><a href="https://www.linkedin.com/in/enzoz/" target="new">LinkedIn</a></p></li>
          </ul>
      </article>
    );
  }
}

export default App;
